// Navigation 1
let navButton = document.querySelector(".nav__button");
let nav = document.querySelector(".nav");
let navItems = document.querySelectorAll(".nav .nav__item a");

function toggleNav(isOpen) {
    navButton.classList.toggle("nav__button--open", isOpen);
    nav.classList.toggle("nav--open", isOpen);
    document.body.classList.toggle("overflow-hidden", isOpen);
}

function closeNav() {
    toggleNav(false);
}

navButton.addEventListener("click", (event) => {
    event.stopPropagation(); // Verhindert das Auslösen des document click-Event-Listeners
    toggleNav(!navButton.classList.contains("nav__button--open"));
});

navItems.forEach((item) => {
	item.addEventListener("click", function (event) {
		const targetElement = document.querySelector(event.target.getAttribute('href'));
		if (targetElement) { // Prüfen, ob das Ziel-Element existiert
			event.preventDefault();
			closeNav();
			setTimeout(() => {
                targetElement.scrollIntoView({
                    behavior: "smooth"
                });
            }, 300);
		}
	});
});

document.addEventListener("click", (event) => {
	// Überprüft, ob weder das navButton-Element noch das nav-Element den Zielknoten des click-Events enthält
	if (!navButton.contains(event.target) && !nav.contains(event.target)) {
		closeNav();
	}
});

// MAIL
let user = 'abart.colors'; 
let domain = 'gmail.com';
let emailElements = document.querySelectorAll('.mail');

emailElements.forEach(function(element) {
    element.innerHTML = '<a href="mailto:' + user + '@' + domain + '">' + user + '@' + domain + '</a>';
});